// Auto-generated by: https://github.com/pmndrs/gltfjsx
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
// import { useLoader } from "@react-three/fiber"
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
// import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'

export default function Hamburger(props) {
    // 旧的方法
    // const model = useLoader(
    //     GLTFLoader,
    //     './models/FlightHelmet/glTF/FlightHelmet.gltf',
    //     (loader) => {
    //         const dracoLoader = new DRACOLoader()
    //         dracoLoader.setDecoderPath('/draco/')
    //         loader.setDRACOLoader(dracoLoader)
    //     }
    // )


    const { nodes, materials } = useGLTF('./models/Hamburger/hamburger.glb')
    return (<>
        {/* 在 primitive 上添加的事件，会响应到每个模型上，需要停止事件传播 */}
        {/* <primitive object={model.scene} scale={1} position-x={-4} onClick={e => e.stopPropagation()} /> */}
        {/* <Clone object={model.scene} scale={1} position-x={-3} /> */}
        <group {...props} dispose={null}>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.meat.geometry}
                material={materials.meatMaterial}
                position={[-0.03, 0.38, 0.04]}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.bottomBun.geometry}
                material={materials.bunMaterial}
                position={[-0.04, -0.01, 0.05]}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.cheese.geometry}
                material={materials.cheeseMaterial}
                position={[-0.05, 0.76, 0]}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.topBun.geometry}
                material={materials.bunMaterial}
                position={[0, 1.17, 0.04]}
                rotation={[0, 0, 3.13]}
                scale={[1.04, 1, 1.04]}
            />
        </group>
    </>)
}

// 预加载
useGLTF.preload('./models/Hamburger/hamburger.glb')
